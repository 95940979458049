const SEO = {
   home: {
      title: "Working to End the HIV Epidemic for Everyone, Everywhere | Gilead HIV",
      description:
         "Explore Gilead HIV to learn about the HIV epidemic. Find HIV resources and educational materials.",
   },
   about: {
      title: "We Are All in This Together | Gilead HIV",
      description:
         "Learn about Gilead's commitment to helping end the HIV epidemic through scientific advancements, community activism, HIV education, corporate giving & more.",
   },
   history: {
      title: "History of the HIV Epidemic | Gilead HIV",
      description:
         "Explore the history of the HIV epidemic. Learn about scientific advancements, health disparities, HIV stigma, and advocacy & activism.",
   },
   state: {
      title: "State of the HIV Epidemic | Gilead HIV",
      description:
         "Explore current HIV data, learn facts about the HIV epidemic in the United States and the populations most affected by HIV. Find HIV statistics and data.",
   },
   "ending-hiv": {
      title: "Plans to Help End the HIV Epidemic | Gilead HIV",
      description:
         "Learn about the national plans to end the HIV epidemic by 2030: End the HIV Epidemic (EHE) Initiative and the National HIV Strategic Plan.",
   },
   "hiv-rapid-start": {
      title: "Rapid Start Initiation | Gilead HIV",
      description:
         "Learn about Rapid Start initiation, an HIV treatment strategy that starts patients on HIV treatment immediately after a positive diagnosis.",
   },
   "care-continuum": {
      title: "The HIV Care Continuum | Gilead HIV",
      description:
         "Learn about the HIV care continuum: HIV testing and prevention, HIV diagnosis, and linking people affected by HIV to treatment and viral suppression.",
   },
   testing: {
      title: "HIV Testing | Gilead HIV",
      description:
         "Explore HIV testing facts, including the latest data, CDC recommendations and barriers to HIV testing. Learn about the types of HIV tests.",
   },
   "social-toolkit": {
      title: "Social Media Toolkit | Gilead HIV",
      description:
         "HIV awareness days help call attention to the impact of HIV. Find downloadable images and captions to help you build your own HIV awareness posts here.",
   },
   events: {
      title: "Events Supporting the HIV Community | Gilead HIV",
      description:
         "Explore Gilead programs working from the ground up with HIV leaders, advocates & communities. Find HIV resources like webinars, conferences & AIDS walks.",
   },
   "blind-angels": {
      title: "Blind Angels | Gilead HIV",
      description:
         "Check out the Gilead HIV docuseries Blind Angels, which follows activists as they work to bring HIV awareness, education, prevention, and care to their communities in the American South.",
   },
   zakia: {
      title: "Blind Angels – Zakia | Gilead HIV",
      description:
         "Meet Zakia McKensey, executive director and founder of Nationz Foundation, as she discusses how her organization's mobile health units help bring HIV care to underserved communities in the American South.",
   },
   jose: {
      title: "Blind Angels – José & Joaquín | Gilead HIV",
      description:
         "Meet HIV advocates José Romero and Joaquín Carcaño who are discussing their work to increase the use of PrEP medicines in the Hispanic/Latino community in the American South.",
   },
   tony: {
      title: "Blind Angels – Tony | Gilead HIV",
      description:
         "Meet Tony Christon-Walker, the former director of Prevention for AIDS Alabama, who helps Black men who have sex with men (MSM) overcome barriers to HIV care in the American South.",
   },
   dazon: {
      title: "Blind Angels – Dázon | Gilead HIV",
      description:
         "Meet Dázon Dixon Diallo, founder and president of SisterLove, Inc., an organization that helps to provide HIV prevention, education, and care for Black women in the American South.",
   },
   "marlene-mcneese": {
      title: "Deeper Dive – Marlene | Gilead HIV",
      description:
         "Marlene McNeese, PACHA co-chair and assistant director of Disease Prevention and Control for the Houston Health Department talks about the impact of the COVID-19 pandemic on HIV testing.",
   },
   "tiffany-west": {
      title: "Deeper Dive – Tiffany West | Gilead HIV",
      description:
         "Learn more about Gilead Sciences’ team working on initiatives such as the Historically Black Colleges and Universities (HBCUs) Roadshow, including an interview with Tiffany West, Senior Director of Advancing Health Equity at Gilead.",
   },
   kayla: {
      title: "Blind Angels – Kayla | Gilead HIV",
      description:
         "Meet Kayla Rena Gore, advocate and co-founder of My Sistah’s House , a grassroots organization that provides emergency housing and other services to the LGBTQIA+ community in Memphis, Tennessee.",
   },
   "champion-danielle": {
      title: "Champions of Change – Danielle | Gilead HIV",
      description: "Danielle Campbell, MPH , a researcher within the AIDS Clinical Trials Group (ACTG) network and community volunteer, represents and creates space for women of color at the forefront of HIV research.",
   },
   "champion-dafina": {
      title: "Champions of Change – Dafina | Gilead HIV",
      description: "Dafina Ward is an attorney, and executive director of the Southern AIDS Coalition believes that ending the HIV epidemic in the South is crucial to helping end the HIV epidemic for all.",
   },
   "champion-dazon": {
      title: "Champions of Change – Dázon | Gilead HIV",
      description: "For over three decades, Dázon Dixon Diallo, founder of SisterLove, Inc has created numerous programs and initiatives centered on giving women and women of color agency in the greater HIV community.",
   },
   "champion-june": {
      title: "Champions of Change – June | Gilead HIV",
      description: "June Gipson, CEO of My Brother’s Keeper, creates and refines curricula for other HIV/AIDS organizations, helping them to bring care and resources into the communities that need it the most.",
   },
   "champion-kayla": {
      title: "Champions of Change – Kayla | Gilead HIV",
      description: "Kayla Quimbley, a leading voice for young people living with HIV, utilizes her lived experience to reframe HIV narratives, create safe spaces, and improve access to comprehensive sexual education.",
   },
   "champion-leisha": {
      title: "Champions of Change – Leisha | Gilead HIV",
      description: "Leisha McKinley, a national HIV/AIDS consultant, has worked with multiple health departments in the South and helps build programs and systems that help Black communities get the care they need and deserve.",
   },
   "champion-linda": {
      title: "Champions of Change – Linda | Gilead HIV",
      description: "Linda H. Scruggs, a long-term survivor of HIV, advocate, and co-executive director of Ribbon, a national nonprofit, focuses on the diverse experiences and backgrounds of women living with HIV.",
   },
   "champion-marlene": {
      title: "Champions of Change – Marlene | Gilead HIV",
      description: "Marlene McNeese, a leading voice on HIV policy and implementation, equips communities with accessible information and programming and advocates for the hardest hit communities to have a seat at the table.",
   },
   "champion-raniyah": {
      title: "Champions of Change – Raniyah | Gilead HIV",
      description: "Raniyah Copeland, founder of Equity & Impact Solutions and former CEO of the Black AIDS Institute focuses on equity advancement, community engagement, and culturally relevant programming.",
   },
   "champion-tori": {
      title: "Champions of Change – Tori | Gilead HIV",
      description: "Tori Cooper, Director of Community Engagement at the Human Rights Campaign and member of the Presidential Advisory Council on HIV/AIDS, proudly represents the voice and intersecting needs of the black, transgender, and HIV communities.",
   },
   "kayla-quimbley": {
      title: "Kayla Quimbley",
      description: "Kayla Quimbley",
   },
   "voices-of-strength": {
      title: "Voices of Strength | Gilead HIV",
      description:
         "Discover Gilead HIV “Voices of Strength” video series which recognizes personal stories of strength, perseverance, and triumph in the face of HIV stigma.",
   },
   "community-commitments": {
      title: "Community Commitments | Gilead HIV",
      description:
         "Hear from organizations that are recipients of Gilead's grant funding, and how they are working together to improve health equity and bring awareness to help end the HIV epidemic.",
   },
   "celebrating-black-women-leaders": {
      title: "Recognizing Black Women Leaders | Gilead HIV",
      description:
         "Get inspired by ten Black Women HIV leaders who are changemakers in advancing health equity and raising awareness in HIV.",
   },
   "living-mosaic": {
      title: "The Living Mosaic | Gilead HIV",
      description:
         "Interact with The Living Mosaic: the Gilead HIV collection of inspirations from the community around helping end the HIV epidemic.",
   },
   "site-map": {
      title: "Site Map | Gilead HIV",
      description:
         "Access the site map for Gilead HIV to find the information you are seeking.",
   },
   "content-index": {
      title: "Deeper Dive - Featured Articles | Gilead HIV",
      description:
         "Find articles, resources, and social posts about what’s going on in the HIV community today and meet the people working to help end the HIV epidemic.",
   },
   "callen-lorde": {
      title: "Deeper Dive – Callen-Lorde | Gilead HIV",
      description:
         "Aruna Krishnakumar, Managing Director of Adolescent & Mobile Health at Callen-Lorde, discusses their Gilead-sponsored National HIV Testing Day event.",
   },
   "24th-ias-conference-2022": {
      title: "Deeper Dive - AIDS 2022 | Gilead HIV",
      description:
         "Check out what we were up to at the 24th International AIDS Society (IAS) Conference, AIDS 2022.",
   },
   "USCHA-conference-2022": {
      title: "Deeper Dive - USCHA Conference 2022 | Gilead HIV",
      description: "Check out a bit about what happened at the 2022 US Conference of HIV/AIDS (USCHA) in San Juan, Puerto Rico, from October 8 to 11. Gilead is proud to have been a presenting sponsor at USCHA 2022.",
   },

   404: {
      title: "404 Error | GILEAD HIV",
      description:
         "Sorry, we could not find the page you are looking for. Browse the GILEAD HIV site map for more.",
   },

   "prep-basics": {
      title: "History of the HIV Epidemic | Gilead HIV",
      description:
         "Explore the history of the HIV epidemic. Learn about scientific advancements, health disparities, HIV stigma, and advocacy & activism.",
   },
};

export default SEO;
