import * as React from "react";
import { Component } from "react";
import { Helmet } from "react-helmet";
import Header from "./header.js";
import MenuMobile from "./menu-mobile.js";
import Footer from "./footer.js";
import JoinUsOnSocial from "./join-us-on-social.js";
import "@fortawesome/fontawesome-free/css/all.css";
import "../../sass/app.scss";
import $ from "jquery";
import Modal from "react-bootstrap/Modal";
import SEO from "./SEO.js";
import SocialShareImg from "../../images/social-share.png";

const InterstitialContent = (props) => {
  if (props.isGeneric) {
    return (
      <p>
        By following this link, you are leaving GileadHIV.com. Gilead provides
        these links as a convenience. But these sites are not controlled by
        Gilead. Gilead is not responsible for their content or your use of them.
        For medical advice, please contact your healthcare provider.
      </p>
    );
  } else {
    return (
      <p>
        By following this link, you are leaving GileadHIV.com. AIDSVu is
        presented by the Rollins School of Public Health at Emory University in
        partnership with Gilead. Gilead provides this link as a convenience. For
        medical advice, please contact your healthcare provider.
      </p>
    );
  }
};

const callenLordeSchema = {
   "@context": "https://schema.org",
   "@type": "NewsArticle",
   "headline": "A National HIV Testing Day Event Powered By Called-Lorde and Sponsored By Gilead",
   "datePublished": "2023-06-19T08:00:00+08:00",
   "dateModified": "2024-05-20T12:00:00+05:00"
};

const dazonSchema = {
   "@context": "https://schema.org",
   "@type": "NewsArticle",
   "headline": "Dázon Dixon Diallo, SisterLove, Inc, and HIV Prevention & Treatment.",
   "datePublished": "2022-03-31T08:00:00+05:00",
   "dateModified": "2024-05-20T12:00:00+05:00"
};

const kaylaSchema = {
   "@context": "https://schema.org",
   "@type": "NewsArticle",
   "headline": "Spotlight On Kayla Rena Gore, Co-Founder of My Sistah's House",
   "datePublished": "2022-05-25T08:00:00+05:00",
   "dateModified": "2024-05-20T12:00:00+05:00"
};

const tiffanyWestSchema = {
   "@context": "https://schema.org",
   "@type": "NewsArticle",
   "headline": "Engaging Historically Black Colleges And Universities On Health Equity To Help End The Hiv Epidemic",
   "datePublished": "2023-02-24T08:00:00+05:00",
   "dateModified": "2024-05-20T12:00:00+05:00"
};

const zakiaSchema = {
   "@context": "https://schema.org",
   "@type": "NewsArticle",
   "headline": "A Conversation With Nationz Foundation Executive Director Zakia McKensey.",
   "datePublished": "2022-03-25T08:00:00+05:00",
   "dateModified": "2024-05-20T12:00:00+05:00"
};

const marleneMcneeseSchema = {
   "@context": "https://schema.org",
   "@type": "NewsArticle",
   "headline": "Understanding The Impact of The COVID-19 Pandemic",
   "datePublished": "2022-06-27T08:00:00+05:00",
   "dateModified": "2024-05-20T12:00:00+05:00"
};


class Layout extends Component {
  constructor() {
    super();
    this.state = {
      mobileMenuOpen: false,
      showInterstitial: false,
      interStitialLink: undefined,
      interstitialGeneric: true,
    };
  }
  toggleMobileMenu = () => {
    this.setState({
      mobileMenuOpen: !this.state.mobileMenuOpen,
    });
  };
  closeInterstitial = () => {
    this.setState({
      showInterstitial: false,
    });
  };

  listenForInterstitialEvents = () => {
    $("a").on("click", (e) => {
      let href = e.currentTarget.href;
      let noInterstitial = $(e.currentTarget).hasClass("no-interstitial");
      if (!href.includes(window.location.hostname) && !noInterstitial) {
        e.preventDefault();
        this.setState({
          showInterstitial: true,
          interStitialLink: href,
          interstitialGeneric:
            !e.currentTarget.href.includes("https://aidsvu.org"),
          analyticsEventCategory:
            e.currentTarget.dataset.analyticsEventCategory,
          analyticsEventLabel: e.currentTarget.dataset.analyticsEventLabel,
        });
      }
    });
  };
  getSnapshotBeforeUpdate(prevProps, prevState) {
    if (prevState.showInterstitial && !this.state.showInterstitial) {
      return window.scrollY;
    }
    return null;
  }
  componentDidUpdate = (prevProps, prevState, snapshot) => {
    if (!prevState.mobileMenuOpen && this.state.mobileMenuOpen) {
      window.scrollTo(0, 0);
    }
    document.body.style.background = this.state.mobileMenuOpen
      ? "#f7f7ee"
      : "#FFF";
    if (snapshot) {
      window.scrollTo(0, snapshot - 1);
    }
  };
  componentDidMount = () => {
    this.listenForInterstitialEvents();
  };
  componentWillUnmount = () => {
    $("a").off("click");
  };
  updateWindowLocation = () => {
    window.open(this.state.interStitialLink, "_blank");
    this.setState({
      showInterstitial: false,
      interStitialLink: undefined,
    });
  };

  render() {
    const show = !this.state.mobileMenuOpen;
    const newChild = React.Children.map(this.props.children, (child) => {
      return React.cloneElement(child, { show });
    });
    return (
      <>
        <Modal
          centered
          show={this.state.showInterstitial}
          onHide={this.closeInterstitial}
          className="interstitial"
        >
          <Modal.Body className="p-0">
            <div className="content">
              <h2 className="title">You are about to leave GileadHIV.com</h2>
              <InterstitialContent isGeneric={this.state.interstitialGeneric} />
              <div className="d-flex justify-content-between">
                <button
                  onClick={this.closeInterstitial}
                  // data-analytics-event-category={this.state.analyticsEventCategory} data-analytics-event-label={`${this.state.analyticsEventLabel} Go Back`}
                  className="button medium light"
                >
                  Go Back
                </button>
                <button
                  onClick={this.updateWindowLocation}
                  className="button medium dark arrow"
                >
                  Continue
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        <Helmet title={SEO[this.props.page].title}>
        <meta name="description" content={SEO[this.props.page].description} />
        <meta property="og:image" content={SocialShareImg} />
        <link rel="stylesheet" href='https://use.typekit.net/zrt6ram.css' />
      
        </Helmet>
         {this.props.page === 'callen-lorde' && (
          <Helmet>
            <script type="application/ld+json">
              {JSON.stringify(callenLordeSchema)}
            </script>
          </Helmet>
        )}

        {this.props.page === 'dazon' && (
          <Helmet>
            <script type="application/ld+json">
              {JSON.stringify(dazonSchema)}
            </script>
          </Helmet>
        )}

        {this.props.page === 'kayla' && (
          <Helmet>
            <script type="application/ld+json">
              {JSON.stringify(kaylaSchema)}
            </script>
          </Helmet>
        )} 

        {this.props.page === 'tiffany-west' && (
          <Helmet>
            <script type="application/ld+json">
              {JSON.stringify(tiffanyWestSchema)}
            </script>
          </Helmet>
        )}

        {this.props.page === 'zakia' && (
          <Helmet>
            <script type="application/ld+json">
              {JSON.stringify(zakiaSchema)}
            </script>
          </Helmet>
        )} 

        {this.props.page === 'marlene-mcneese' && (
          <Helmet>
            <script type="application/ld+json">
              {JSON.stringify(marleneMcneeseSchema)}
            </script>
          </Helmet>
        )}
        <Header
          section={this.props.section}
          mobileMenuOpen={this.state.mobileMenuOpen}
          toggleMobileMenu={this.toggleMobileMenu}
        />
        <MenuMobile
          section={this.props.section}
          show={this.state.mobileMenuOpen}
        />
        {newChild}
        <JoinUsOnSocial
          show={
            !this.state.mobileMenuOpen &&
            this.props.section !== "site-map" &&
            this.props.section !== "404"
          }
        />
        <Footer
          page={this.props.page}
          mobileMenuOpen={this.state.mobileMenuOpen}
          isSiteMap404={
            this.props.section === "site-map" || this.props.section === "404"
          }
        />
      </>
    );
  }
}

export default Layout;
